import React from "react"
import { Link } from "gatsby"

import Layout from "@components/layout"
import SEO from "@components/seo"

import HourlyITBanner from "@images/hero-bg2.jpg"

const HourlyIT = () => (
	<Layout>
		<SEO
			title="IT Services Boulder County | Tech Support & IT Consulting | NeighborhoodIT"
			description="NeighborhoodIT offers enterprise-class IT services and tech support solutions to Boulder County to small businesses in order to help you level the competitive playing field."
		/>
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${HourlyITBanner})`,
				backgroundPosition: `bottom`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white">
						<div className="col-12">
							<h1>What's the best thing about our IT support?</h1>
							<p>We give you the help you need, right when you need it.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between align-items-center">
					<div className="col-12">
						<h1>Hourly IT Support Services</h1>
						<p>
							Imagine having a team of remarkably friendly and exceedingly
							competent IT professionals at your disposal-without having to pay
							their salaries.
						</p>
						<h2>Sounds good, doesn't it?</h2>
						<p>
							We provide affordable on-demand IT support services to clients
							that want responsive IT support when they need it-and only when
							they need it. Our rapid-response IT support services are based on
							a per hour or per incident basis. We're available to troubleshoot
							any problem you might have, from the nagging kind to the
							shouldn't-have-gotten-out-of-bed variety. Help is just a call
							away.
						</p>
						<p className="font-weight-bold">
							Call on our IT support services if you need...
						</p>
						<ul>
							<li>Software installation or upgrade</li>
							<li>Virus removal or anti-virus installation</li>
							<li>PC or Mac overhaul/performance boost</li>
							<li>Training in a newly installed application</li>
							<li>Hardware installation or upgrade</li>
						</ul>
						<p>
							<Link to="/#contact">Contact us</Link> today for fast and friendly
							IT support services.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default HourlyIT
